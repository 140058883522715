import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: radial-gradient(rgb(255 255 255) 0%, rgb(113 113 113) 100%);
`;

const Main = styled.div`
  background: #c0c0c0;
  border-width: 20px;
  border-style: solid;
  border-color: #ffffff #7d7d7d #7d7d7d #ffffff;
  min-width: 50%;
  min-height: 525px;
  padding:10px;
  height: 78%;
  margin: 0;
`;

interface PageSharedLayoutInterface {
  children: React.ReactNode;
}

export const PageSharedLayout = ({ children }: PageSharedLayoutInterface) => {
  return (
    <Layout>
      <Main>{children}</Main>
    </Layout>
  );
};
