import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import {
  MinesweeperSquareValueOptions,
  MinesweeperGridSettings,
  MinesweeperDifficultyOptions,
  generateGridVisibleValuesAfterFloodFill,
  generateInitialGrids
} from "@minesweeper-versus/shared";
import { GameGrid } from "../presentation/GameGrid";
import { GameGridHeader } from "../presentation/GameGridHeader";
import { Button } from "../presentation/Button";
import { GameGridBanner } from "../presentation/GameGridBanner";
import { Dropdown, DropdownItem } from "../presentation/Dropdown";
import { TimerContainer, TimerStates } from "../container/TimerContainer";

const SingleplayerOfflinePageLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
`;

const SingleplayerOfflinePageBannerBox = styled.div`
  position: absolute;
  width: 75%;
`;

export const SingleplayerOfflinePage = () => {
  const navigate = useNavigate();

  const [gridValues, setGridValues] = useState<
    MinesweeperSquareValueOptions[][]
  >([[0]]);
  const [gridVisibleValues, setGridVisibleValues] = useState<
    MinesweeperSquareValueOptions[][]
  >([[0]]);
  const [gameDifficulty, setGameDifficulty] =
    useState<MinesweeperDifficultyOptions>("EASY");
  const [timerState, setTimerState] = useState<TimerStates>("stop");
  const [isGameDone, setIsGameDone] = useState(false);
  const [remainingSquares, setRemainingSquares] = useState(-1);

  const squareClick: (y: number, x: number) => () => void = (y, x) => () => {
    if (isGameDone) {
      return;
    }

    if (gridVisibleValues[y][x] !== "u" || gridVisibleValues[y][x] === "f") {
      return;
    }

    if (timerState !== "start") {
      setTimerState("start");
    }

    if (gridValues[y][x] === "m") {
      setIsGameDone(true);
      setTimerState("stop");
    }

    if (gridValues[y][x] > 0) {
      let tempArr = [...gridVisibleValues];
      tempArr[y][x] = gridValues[y][x];
      setRemainingSquares((prevRemainingSquares) => prevRemainingSquares - 1);
      setGridVisibleValues(tempArr);
      return;
    }

    if (gridValues[y][x] === 0) {
      let tempValues = generateGridVisibleValuesAfterFloodFill(
        y,
        x,
        gameDifficulty,
        gridVisibleValues,
        gridValues
      );

      setRemainingSquares(
        (prevRemainingSquares) =>
          prevRemainingSquares - tempValues.tempSquareCounts
      );
      setGridVisibleValues(tempValues.tempArr);
    }
  };

  const squareAlternateClick = (y: number, x: number) => (): void => {
    if (gridVisibleValues[y][x] === "f") {
      let tempArr = [...gridVisibleValues];
      tempArr[y][x] = "u";
      setGridVisibleValues(tempArr);
      return;
    }

    if (gridVisibleValues[y][x] !== "u") {
      return;
    }

    let tempArr = [...gridVisibleValues];
    tempArr[y][x] = "f";
    setGridVisibleValues(tempArr);
  };

  const onResetClick = () => {
    setTimerState("reset");
    setIsGameDone(false);

    const tempGrids = generateInitialGrids(gameDifficulty);

    setRemainingSquares(
      MinesweeperGridSettings[gameDifficulty].gridY *
        MinesweeperGridSettings[gameDifficulty].gridX -
        MinesweeperGridSettings[gameDifficulty].numOfMines
    );
    setGridVisibleValues(tempGrids.gridVisibleValues);
    setGridValues(tempGrids.gridValues);
  };

  useEffect(() => {
    if (isGameDone) return;

    setTimerState("reset");

    const tempGrids = generateInitialGrids(gameDifficulty);

    setRemainingSquares(
      MinesweeperGridSettings[gameDifficulty].gridY *
        MinesweeperGridSettings[gameDifficulty].gridX -
        MinesweeperGridSettings[gameDifficulty].numOfMines
    );
    setGridVisibleValues(tempGrids.gridVisibleValues);
    setGridValues(tempGrids.gridValues);
  }, [isGameDone, gameDifficulty]);

  useEffect(() => {
    if (remainingSquares === 0) {
      setIsGameDone(true);
      setTimerState("stop");
    }
  }, [remainingSquares]);

  return (
    <SingleplayerOfflinePageLayout>
      <GameGridHeader
        left={
          <Dropdown label="Menu">
            <DropdownItem
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setGameDifficulty("EASY");
              }}
            >
              Easy
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setGameDifficulty("NORMAL");
              }}
            >
              Normal
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setGameDifficulty("HARD");
              }}
            >
              Hard
            </DropdownItem>
          </Dropdown>
        }
        center={<Button onClick={onResetClick}>Reset</Button>}
        right={<TimerContainer timerState={timerState} />}
      />
      <GameGrid
        onSquareClick={squareClick}
        onSquareAlternateClick={squareAlternateClick}
        gridValues={gridVisibleValues}
        gameSize={gameDifficulty}
      ></GameGrid>
      {isGameDone ? (
        <SingleplayerOfflinePageBannerBox>
          <GameGridBanner>
            {remainingSquares === 0 ? "You Win" : "You Lose"}
          </GameGridBanner>
        </SingleplayerOfflinePageBannerBox>
      ) : null}
    </SingleplayerOfflinePageLayout>
  );
};
