import styled from "styled-components";

interface GameGridBannerInterface {
  children: React.ReactNode;
}

const Banner = styled.div`
  border: 3px solid black;
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
  font-family: "Press Start 2P", cursive;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const GameGridBanner = (props: GameGridBannerInterface) => {
  return <Banner>{props.children}</Banner>;
};
