import { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  font-family: "Press Start 2P", cursive;
`;

export type TimerStates = "start" | "stop" | "reset";
interface TimerContainerInterface {
  timerState: TimerStates;
}

export const TimerContainer = ({ timerState }: TimerContainerInterface) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (timerState === "start") {
      setSeconds(0);

      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }

    if (timerState === "reset") {
      setSeconds(0);
    }

    return () => clearInterval(interval);
  }, [timerState]);

  return <Container>{seconds}s</Container>;
};
