import styled from "styled-components";

interface SelectInterface {
  disabled?: boolean;
  options?: string[];
  onClick?: (option: string) => void;
  style?: React.CSSProperties;
}

const StyledSelected = styled.select`
  width: 100%;
  font-family: "Press Start 2P";
  font-size: 10px;
  padding: 6px;
`;

const StyledOption = styled.option`
  margin: 3px 2px;
`;

export const Select = ({
  disabled,
  options,
  onClick,
  style
}: SelectInterface) => {
  return (
    <StyledSelected style={style} size={10}>
      {options?.map((opt) => (
        <StyledOption
          key={opt}
          onClick={() => {
            if (onClick) {
              onClick(opt);
            }
          }}
          value={opt}
        >
          {opt}
        </StyledOption>
      ))}
    </StyledSelected>
  );
};
