import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../presentation/Button";
import { useAuth } from "react-oidc-context";
import MinesweeperVersusPaths from "~/helpers/constants/MinesweeperVersusPaths";

const HomePageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "Press Start 2P", cursive;

  & > * {
    margin: 5px 0px;
  }
`;

const Title = styled.h1`
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
  & > span {
    color: red;
  }
`;

const TopContent = styled.div`
  width: 100%;
  margin-bottom:16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MainContentLayout = styled.div`
  width: 100%;
  display: flex;

  & > * {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
`;

const LeftContent = styled.div`
  padding: 0 64px;
  text-decoration: none;

  & > * {
    margin: 5px 0px;
  }
`;

const RightContent = styled.div``;

export const HomePage = () => {
  const auth = useAuth();

  return (
    <HomePageLayout>
      <Title>
        Minesweeper <span>Versus.</span>
      </Title>
      <TopContent>
        {auth.isAuthenticated ? 
          <Button onClick={() => auth.signoutRedirect()}>
            Logout
          </Button> :
          <Button onClick={() => auth.signinRedirect()}>
            Login
          </Button>
        }
      </TopContent>
      <MainContentLayout>
        <LeftContent>
          <Link to={MinesweeperVersusPaths.singleplayerOffline}>
            <Button>
              Single Player (Offline)
            </Button>
          </Link>
          <Link to={MinesweeperVersusPaths.singleplayerRanked}>
            <Button disabled={!auth.isAuthenticated}>
              Single Player (Ranked)
            </Button>
          </Link>
          <Link to={MinesweeperVersusPaths.multiplayerClassic}>
            <Button disabled={!auth.isAuthenticated}>
              Multi Player (Classic)
            </Button>
          </Link>
        </LeftContent>
        <RightContent></RightContent>
      </MainContentLayout>
    </HomePageLayout>
  );
};
