"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinesweeperMultiplayerClassicGameStatus = void 0;
var MinesweeperMultiplayerClassicGameStatus;
(function (MinesweeperMultiplayerClassicGameStatus) {
    MinesweeperMultiplayerClassicGameStatus["AWAITING_PLAYER_TO_JOIN"] = "AWAITING_PLAYER_TO_JOIN";
    MinesweeperMultiplayerClassicGameStatus["AWAITING_ROOM_CREATION"] = "AWAITING_ROOM_CREATION";
    MinesweeperMultiplayerClassicGameStatus["AWAITING_PLAYER_ROOM_SELECTION"] = "AWAITING_PLAYER_ROOM_SELECTION";
    MinesweeperMultiplayerClassicGameStatus["AWAITING_JOIN_REQUEST"] = "AWAITING_JOIN_REQUEST";
    MinesweeperMultiplayerClassicGameStatus["AWAITING_LEVEL_SELECTION"] = "AWAITING_LEVEL_SELECTION";
    MinesweeperMultiplayerClassicGameStatus["AWAITING_GAME_START"] = "AWAITING_GAME_START";
    MinesweeperMultiplayerClassicGameStatus["AWAITING_PLAYER_MOVE"] = "AWAITING_PLAYER_MOVE";
    MinesweeperMultiplayerClassicGameStatus["AWAITING_OTHER_PLAYER_MOVE"] = "AWAITING_OTHER_PLAYER_MOVE";
    MinesweeperMultiplayerClassicGameStatus["PLAYER_LOST"] = "PLAYER_LOST";
    MinesweeperMultiplayerClassicGameStatus["PLAYER_WON"] = "PLAYER_WON";
})(MinesweeperMultiplayerClassicGameStatus = exports.MinesweeperMultiplayerClassicGameStatus || (exports.MinesweeperMultiplayerClassicGameStatus = {}));
