import { TScore } from "@minesweeper-versus/shared";
import styled from "styled-components";

interface ScoreTableInterface {
    scores:TScore[]
  }

const Table = styled.table`
    width:80%
`;
const TableBody = styled.tbody``;
const TableRow = styled.tr``;
const TableHeading = styled.th``;
const TableColumn = styled.td`
    text-align: center;
`;

export const ScoreTable = ({scores}:ScoreTableInterface) => {
    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableHeading>Player</TableHeading>
                    <TableHeading>Time</TableHeading>
                </TableRow>
                {scores.map(score =>                 
                <TableRow key={score.userName + score.totalTime}>
                    <TableColumn>{score.userName}</TableColumn>
                    <TableColumn>{score.totalTime/1000}s</TableColumn>
                </TableRow>)}
            </TableBody>
        </Table>
    );
};