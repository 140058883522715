import {
  MinesweeperSquareValueOptions,
  MinesweeperMultiplayerClassicGameStatus,
  MinesweeperDifficultyOptions
} from "@minesweeper-versus/shared";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { WebSocketContext } from "~/context/WebSocketContext";
import { Button } from "../presentation/Button";
import { Select } from "../presentation/Select";
import { useNavigate } from "react-router";
import { GameGridHeader } from "../presentation/GameGridHeader";
import { GameGrid } from "../presentation/GameGrid";
import { Dropdown, DropdownItem } from "../presentation/Dropdown";
import { GameGridBanner } from "../presentation/GameGridBanner";

const MultiPlayerClassicPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
`;

const MultiPlayerClassicPageSelectDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const MultiPlayerRankedPageBannerBox = styled.div`
  position: absolute;
  width: 75%;
`;

export const MultiplayerClassicPage = () => {
  const navigate = useNavigate();
  const [gridVisibleValues, setGridVisibleValues] = useState<
    MinesweeperSquareValueOptions[][]
  >([[0]]);
  const [roomCode, setRoomCode] = useState<string>("");
  const [roomList, setRoomList] = useState<string[]>([]);
  const [pageState, setPageState] =
    useState<MinesweeperMultiplayerClassicGameStatus>(
      MinesweeperMultiplayerClassicGameStatus.AWAITING_PLAYER_ROOM_SELECTION
    );
  const [gameDifficulty, setGameDifficulty] =
    useState<MinesweeperDifficultyOptions>("EASY");
  const [playerState, setPlayerState] = useState<number>(0);
  const [player1Score, setPlayer1Score] = useState<number>(0);
  const [player2Score, setPlayer2Score] = useState<number>(0);

  const WebSocket = useContext(WebSocketContext);

  useEffect(() => {
    WebSocket.addMessageHandler("mpc:roomListRetrieve", (payload: string[]) => {
      setRoomList(payload);
    });

    WebSocket.addMessageHandler(
      "mpc:updatePageStatus",
      (payload: MinesweeperMultiplayerClassicGameStatus) => {
        setPageState(payload);
      }
    );

    WebSocket.addMessageHandler("mpc:roomCreate", (payload: string) => {
      setRoomCode(payload);
      setPlayerState(1);
      setPageState(
        MinesweeperMultiplayerClassicGameStatus.AWAITING_PLAYER_TO_JOIN
      );
    });

    WebSocket.addMessageHandler(
      "mpc:gridUpdate",
      (payload: MinesweeperSquareValueOptions[][]) => {
        setGridVisibleValues(payload);
      }
    );

    WebSocket.addMessageHandler(
      "mpc:scoreUpdate",
      (payload: { player1: number; player2: number }) => {
        setPlayer1Score(payload.player1);
        setPlayer2Score(payload.player2);
      }
    );

    WebSocket.sendMessage("mpc:roomListRequest");

    return () => {
      WebSocket.removeMessageHandler("mpc:roomListRetrieve");
    };
  }, [WebSocket]);

  const onCreateRoomClick = () => {
    WebSocket.sendMessage("mpc:createRoomRequest");
    setPageState(
      MinesweeperMultiplayerClassicGameStatus.AWAITING_ROOM_CREATION
    );
  };

  const onJoinRoomClick = () => {
    if (roomCode === "") {
      return;
    }

    WebSocket.sendMessage("mpc:joinRoomRequest", roomCode);
    setPlayerState(2);
    setPageState(MinesweeperMultiplayerClassicGameStatus.AWAITING_JOIN_REQUEST);
  };

  const onDifficultyButtonClick = (
    difficulty: MinesweeperDifficultyOptions
  ) => {
    setGameDifficulty(difficulty);
    WebSocket.sendMessage("mpc:difficultySelect", difficulty);
    setPageState(MinesweeperMultiplayerClassicGameStatus.AWAITING_GAME_START);
  };

  const onSquareClick = (y: number, x: number) => (): void => {
    if (
      pageState === MinesweeperMultiplayerClassicGameStatus.PLAYER_LOST ||
      pageState === MinesweeperMultiplayerClassicGameStatus.PLAYER_WON ||
      pageState !== MinesweeperMultiplayerClassicGameStatus.AWAITING_PLAYER_MOVE
    ) {
      return;
    }

    if (gridVisibleValues[y][x] !== "u" || gridVisibleValues[y][x] === "f") {
      return;
    }

    WebSocket.sendMessage("mpc:gridClick", {
      y,
      x
    });
  };

  let renderedView;

  switch (pageState) {
    case MinesweeperMultiplayerClassicGameStatus.AWAITING_PLAYER_ROOM_SELECTION:
      renderedView = (
        <>
          <Button onClick={onCreateRoomClick}>Create Room</Button>
          <MultiPlayerClassicPageSelectDiv>
            <Select
              style={{ marginBottom: 10 }}
              onClick={(option: string) => {
                setRoomCode(option);
              }}
              options={roomList}
            />
            <Button onClick={onJoinRoomClick}>Join Room</Button>
          </MultiPlayerClassicPageSelectDiv>
          <Button
            onClick={() => {
              navigate("/");
            }}
          >
            Go Back
          </Button>
        </>
      );
      break;
    case MinesweeperMultiplayerClassicGameStatus.AWAITING_ROOM_CREATION:
      renderedView = "Awaiting Room Creation";
      break;
    case MinesweeperMultiplayerClassicGameStatus.AWAITING_PLAYER_TO_JOIN:
      renderedView = <>Awaiting Player to join</>;
      break;
    case MinesweeperMultiplayerClassicGameStatus.AWAITING_JOIN_REQUEST:
      renderedView = <>Awaiting Join Request</>;
      break;
    case MinesweeperMultiplayerClassicGameStatus.AWAITING_GAME_START:
      renderedView = <>Awaiting Game to Start</>;
      break;
    case MinesweeperMultiplayerClassicGameStatus.AWAITING_LEVEL_SELECTION:
      renderedView = (
        <>
          <Button
            onClick={() => {
              onDifficultyButtonClick("EASY");
            }}
          >
            EASY
          </Button>
          <Button
            onClick={() => {
              onDifficultyButtonClick("NORMAL");
            }}
          >
            NORMAL
          </Button>
          <Button
            onClick={() => {
              onDifficultyButtonClick("HARD");
            }}
          >
            HARD
          </Button>
        </>
      );
      break;
    case MinesweeperMultiplayerClassicGameStatus.AWAITING_PLAYER_MOVE:
    case MinesweeperMultiplayerClassicGameStatus.AWAITING_OTHER_PLAYER_MOVE:
    case MinesweeperMultiplayerClassicGameStatus.PLAYER_LOST:
    case MinesweeperMultiplayerClassicGameStatus.PLAYER_WON:
      renderedView = (
        <>
          <GameGridHeader
            left={
              playerState === 1 ? (
                <Dropdown label="Menu">
                  <DropdownItem
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Home
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      onDifficultyButtonClick("EASY");
                    }}
                  >
                    Easy
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      onDifficultyButtonClick("NORMAL");
                    }}
                  >
                    Normal
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      onDifficultyButtonClick("HARD");
                    }}
                  >
                    Hard
                  </DropdownItem>
                </Dropdown>
              ) : null
            }
            center={
              pageState ===
              MinesweeperMultiplayerClassicGameStatus.AWAITING_PLAYER_MOVE
                ? "Your Turn"
                : "Awaiting Other Player's Turn"
            }
            right={
              <p>
                p1:{player1Score} p2:{player2Score}
              </p>
            }
          />
          <GameGrid
            gameSize={gameDifficulty}
            gridValues={gridVisibleValues}
            onSquareClick={onSquareClick}
          ></GameGrid>
          {pageState === MinesweeperMultiplayerClassicGameStatus.PLAYER_LOST ||
          pageState === MinesweeperMultiplayerClassicGameStatus.PLAYER_WON ? (
            <MultiPlayerRankedPageBannerBox>
              <GameGridBanner>
                <>
                  <p>
                    {pageState ===
                    MinesweeperMultiplayerClassicGameStatus.PLAYER_LOST
                      ? "You Lost"
                      : "You Won"}
                  </p>
                </>
              </GameGridBanner>
            </MultiPlayerRankedPageBannerBox>
          ) : null}
        </>
      );
      break;
    default:
      renderedView = "";
  }

  return (
    <MultiPlayerClassicPageLayout>{renderedView}</MultiPlayerClassicPageLayout>
  );
};
