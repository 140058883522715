"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinesweeperGridSettings = void 0;
exports.MinesweeperGridSettings = {
    EASY: {
        gridX: 10,
        gridY: 8,
        numOfMines: 10,
        squareSize: 60
    },
    NORMAL: {
        gridX: 18,
        gridY: 14,
        numOfMines: 40,
        squareSize: 33
    },
    HARD: {
        gridX: 24,
        gridY: 20,
        numOfMines: 99,
        squareSize: 25
    }
};
