"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinesweeperVersusUrls = void 0;
var productionMinesweeperVersusUrls = {
    socketServer: "wss://socket-server.minesweeperversus.net",
    server: "https://server.minesweeperversus.net",
};
var testMinesweeperVersusUrls = {
    socketServer: "ws://localhost:8080",
    server: "http://localhost:8060",
};
exports.MinesweeperVersusUrls = {
    production: productionMinesweeperVersusUrls,
    test: testMinesweeperVersusUrls,
};
