import { Routes, Route, BrowserRouter } from "react-router-dom";
import { WebSocketContextProvider } from "./context/WebSocketContext";
import { HomePage } from "./components/page/HomePage";
import { PageSharedLayout } from "./components/page/layout/PageSharedLayout";
import { SingleplayerOfflinePage } from "./components/page/SingleplayerOffinePage";
import { SingleplayerRankedPage } from "./components/page/SinglePlayerRankedPage";
import { GlobalStyle } from "./style/GlobalStyles";
import MinesweeperVersusPaths from "./helpers/constants/MinesweeperVersusPaths";
import { AuthProvider } from "react-oidc-context";
import MinesweeperVersusOidcConfig from "./helpers/constants/MinesweeperVersusOidcConfig";
import { WebSocketConfigurer } from "./components/page/shared/WebSocketConfigurer";
import { MultiplayerClassicPage } from "./components/page/MultiPlayerClassicPage";

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <AuthProvider {...MinesweeperVersusOidcConfig}>
        <BrowserRouter>
          <WebSocketContextProvider>
            <WebSocketConfigurer>
              <PageSharedLayout>
                <Routes>
                  <Route
                    path={MinesweeperVersusPaths.singleplayerOffline}
                    element={<SingleplayerOfflinePage />}
                  ></Route>
                  <Route
                    path={MinesweeperVersusPaths.singleplayerRanked}
                    element={<SingleplayerRankedPage />}
                  ></Route>
                  <Route
                    path={MinesweeperVersusPaths.multiplayerClassic}
                    element={<MultiplayerClassicPage />}
                  ></Route>
                  <Route path="/*" element={<HomePage />}></Route>
                </Routes>
              </PageSharedLayout>
            </WebSocketConfigurer>
          </WebSocketContextProvider>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
