import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "./Button";

interface DropdownIntereface {
  children?: React.ReactNode;
  label: string;
}

interface DropdownItemIntereface {
  onClick?: () => void;
  children: React.ReactNode;
}

const DropdownBox = styled.div`
  position: relative;
`;

const DropdownItemsBox = styled.div`
  position: absolute;
  left: 5%;
`;

export const Dropdown = ({ children, label }: DropdownIntereface) => {
  const [isActive, setIsActive] = useState(false);
  const DropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        DropdownRef.current &&
        !DropdownRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <DropdownBox ref={DropdownRef}>
      <Button
        onClick={() => {
          setIsActive((prevIsActive) => !prevIsActive);
        }}
      >
        {label}
      </Button>
      {isActive ? <DropdownItemsBox>{children}</DropdownItemsBox> : null}
    </DropdownBox>
  );
};

export const DropdownItem = ({ onClick, children }: DropdownItemIntereface) => {
  return (
    <Button width={75} onClick={onClick}>
      {children}
    </Button>
  );
};
