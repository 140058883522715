import styled from "styled-components";
import {
  MinesweeperDifficultyOptions,
  MinesweeperSquareValueOptions
} from "@minesweeper-versus/shared";
import { MinesweeperSquare } from "./MinesweeperSquare";

interface GameGridInterface {
  gridValues: MinesweeperSquareValueOptions[][];
  gameSize: MinesweeperDifficultyOptions;
  testid?: string;
  onSquareClick: (y: number, x: number) => () => void;
  onSquareAlternateClick?: (y: number, x: number) => () => void;
}

const Grid = styled.div`
  border: 1px solid black;
  width: 600px;
  display: flex;
  flex-wrap: wrap;
`;

export const GameGrid = ({
  gridValues,
  gameSize,
  onSquareClick,
  onSquareAlternateClick = (x: number, y: number) => () => {},
  testid
}: GameGridInterface) => {
  const squares = [];
  for (let i = 0; i < gridValues.length; i++) {
    for (let j = 0; j < gridValues[0].length; j++) {
      squares.push(
        <MinesweeperSquare
          value={gridValues[i][j]}
          size={gameSize}
          onClick={onSquareClick(i, j)}
          onAlternateClick={onSquareAlternateClick(i, j)}
          mobile={false}
          key={i + "-" + j}
        />
      );
    }
  }

  return <Grid data-testid={testid}>{squares}</Grid>;
};
