import { getMinesweeperVersusUrls } from "@minesweeper-versus/shared";
import React, { useRef } from "react";

const defaultContextState = {
  addMessageHandler: () => {},
  removeMessageHandler: () => {},
  connect: () => {},
  sendMessage: () => {}
};

export const WebSocketContext =
  React.createContext<ISocket>(defaultContextState);

interface ISocket {
  addMessageHandler: <T extends unknown>(
    message: string,
    handler: (payload: T) => void
  ) => void;
  removeMessageHandler: (message: string) => void;
  connect: () => void;
  sendMessage: <T extends unknown>(message: string, payload?: T) => void;
}

interface WebSocketContextProviderInterface {
  children: React.ReactNode;
}


export const WebSocketContextProvider = ({
  children
}: WebSocketContextProviderInterface) => {
  const messagesHandlerMap = useRef<Record<string, Function>>({});
  const webSocket = useRef<WebSocket>();

  function connect(){
    webSocket.current = new WebSocket(getMinesweeperVersusUrls(process.env.NODE_ENV).socketServer);

    webSocket.current.onmessage = function (event: MessageEvent<any>) {
      let msg = JSON.parse(event.data);
      let messageHandler = messagesHandlerMap.current[msg.type];

      if(messageHandler){
        messageHandler(msg.payload);
      }else{
        console.log("Missing message handler for: ", msg.type);
      }
    };
  }

  function addMessageHandler<T>(
    message: string,
    handler: (payload: T) => void
  ) {
    messagesHandlerMap.current[message] = handler;
  }

  function removeMessageHandler(message: string) {
    delete messagesHandlerMap.current[message];
  }

  function sendMessage<T>(type: string, payload?: T) {
    let msg = JSON.stringify({
      type,
      payload
    });
    
    if(webSocket.current === undefined){
      throw new Error("WebSocket has not been initialized.")
    }

    webSocket.current.send(msg);
  }

  const Socket = {
    addMessageHandler,
    removeMessageHandler,
    connect,
    sendMessage
  };

  return (
    <WebSocketContext.Provider value={Socket}>
      {children}
    </WebSocketContext.Provider>
  );
};
