"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMinesweeperVersusUrls = void 0;
var MinesweeperVersusUrls_1 = require("../constants/MinesweeperVersusUrls");
var getMinesweeperVersusUrls = function (env) {
    if (env === "production") {
        return MinesweeperVersusUrls_1.MinesweeperVersusUrls.production;
    }
    else {
        return MinesweeperVersusUrls_1.MinesweeperVersusUrls.test;
    }
};
exports.getMinesweeperVersusUrls = getMinesweeperVersusUrls;
