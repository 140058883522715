import { AuthProviderProps } from "react-oidc-context";

const productionMinesweeperVersusOidcConfig: AuthProviderProps = {
  authority: "https://identity.minesweeperversus.net",
  client_id: "msv.ui",
  redirect_uri: "https://minesweeperversus.net/signin-oidc",
  scope: "openid profile scope1"
};

const testMinesweeperVersusOidcConfig: AuthProviderProps = {
  authority: "https://localhost:5001",
  client_id: "msv.ui",
  redirect_uri: "http://localhost:3000/signin-oidc",
  scope: "openid profile scope1"
};

const MinesweeperVersusOidcConfig =
  process.env.NODE_ENV === "production"
    ? productionMinesweeperVersusOidcConfig
    : testMinesweeperVersusOidcConfig;

export default MinesweeperVersusOidcConfig;
