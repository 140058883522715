import styled from "styled-components";

const GameGridHeaderLayout = styled.div`
  box-sizing: border-box;
  border: 2px solid black;
  height: 45px;
  width: 602px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-family: "Press Start 2P";
  font-size: 10px;
`;

interface GameGridHeaderInterface {
  left?: React.ReactNode;
  right?: React.ReactNode;
  center?: React.ReactNode;
}

export const GameGridHeader = ({
  left,
  right,
  center
}: GameGridHeaderInterface) => {
  return (
    <GameGridHeaderLayout>
      <div data-testid="gameGridHeader-left-component">{left}</div>
      <div data-testid="gameGridHeader-center-component">{center}</div>
      <div data-testid="gameGridHeader-right-component">{right}</div>
    </GameGridHeaderLayout>
  );
};
