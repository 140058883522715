import { useContext } from "react";
import { useAuth } from "react-oidc-context";
import { WebSocketContext } from "~/context/WebSocketContext";

interface IWebSocketConfigurer {
    children: React.ReactNode;
}

export const WebSocketConfigurer = ( {children}:IWebSocketConfigurer ) => {
    const auth = useAuth();
    const webSocket = useContext(WebSocketContext);

    if(auth.isAuthenticated){
        webSocket.connect();
    }

    
    return (<>{children}</>)
};