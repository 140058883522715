import styled from "styled-components";
import {
  MinesweeperSquareValueOptions,
  MinesweeperDifficultyOptions,
  MinesweeperSquareMobileSizeOptions,
  MinesweeperSquareNumberColourOptions,
  MinesweeperSquareSizeOptions,
  MinesweeperSquareNumberOptions
} from "@minesweeper-versus/shared";
import mineImg from "~/assets/redmine.png";
import flagImg from "~/assets/flag.png";

interface MinesweeperSquareProps {
  value: MinesweeperSquareValueOptions;
  size: MinesweeperDifficultyOptions;
  mobile: boolean;
  testid?: string;
  onClick?: () => void;
  onAlternateClick?: () => void;
}

const Square = styled.div<MinesweeperSquareProps>`
  box-sizing: border-box;
  border: 1px solid #767676;
  height: ${(props) =>
    props.mobile
      ? MinesweeperSquareMobileSizeOptions[props.size]
      : MinesweeperSquareSizeOptions[props.size]}px;
  width: ${(props) =>
    props.mobile
      ? MinesweeperSquareMobileSizeOptions[props.size]
      : MinesweeperSquareSizeOptions[props.size]}px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Press Start 2P", cursive;
  font-size: 20px;
  color: ${(props) =>
    props.value >= 0
      ? MinesweeperSquareNumberColourOptions[
          props.value as MinesweeperSquareNumberOptions
        ]
      : "#767676"};

  background-image: ${(props) =>
    props.value === "m"
      ? `url(${mineImg})`
      : props.value === "f"
      ? `url(${flagImg})`
      : ""};
  background-size: cover;
  background: ${(props) => (props.value >= 0 ? "#c0c0c0" : "")};
  border-width: ${(props) => (props.value >= 0 ? "1px" : "5px")};
  border-color: ${(props) =>
    props.value >= 0 ? "#767676" : "#ffffff #7d7d7d #7d7d7d #ffffff"};
  user-select: none; 

  &:hover {
    background: ${(props) => (props.value === "u" ? "#8a8888" : "")};
    cursor: pointer;
  }
`;

export const MinesweeperSquare = ({
  value,
  size,
  mobile,
  testid,
  onClick = () => {},
  onAlternateClick = () => {}
}: MinesweeperSquareProps) => {
  const onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onAlternateClick();
  };

  return (
    <Square
      value={value}
      size={size}
      mobile={mobile}
      onClick={() => onClick()}
      onContextMenu={onContextMenu}
      data-testid={testid}
    >
      {value > 0 ? value : null}
    </Square>
  );
};
