import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import {
  MinesweeperSquareValueOptions,
  MinesweeperDifficultyOptions,
  TMinesweeperVersusSinglePlayerRankedGameStatus,
  TScore
} from "@minesweeper-versus/shared";
import { WebSocketContext } from "~/context/WebSocketContext";
import { GameGrid } from "../presentation/GameGrid";
import { GameGridBanner } from "../presentation/GameGridBanner";
import { GameGridHeader } from "../presentation/GameGridHeader";
import { Dropdown, DropdownItem } from "../presentation/Dropdown";
import { useNavigate } from "react-router";
import { TimerContainer, TimerStates } from "../container/TimerContainer";
import { Button } from "../presentation/Button";
import { useAuth } from "react-oidc-context";
import { ScoreTable } from "../presentation/ScoreTable";

const SingleplayerRankedPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
`;

const SingleplayerRankedPageBannerBox = styled.div`
  position: absolute;
  width: 75%;
`;

export const SingleplayerRankedPage = () => {
  const navigate = useNavigate();

  const [gridVisibleValues, setGridVisibleValues] = useState<
    MinesweeperSquareValueOptions[][]
  >([[0]]);
  const [timerState, setTimerState] = useState<TimerStates>("stop");
  const [isGameDone, setIsGameDone] = useState<boolean>(false);
  const [gameDifficulty, setGameDifficulty] =
    useState<MinesweeperDifficultyOptions>("EASY");
  const [didPlayerWin, setDidPlayerWin] = useState(false);
  const [didTopTenScoreChange, setTopTenScoreChange] = useState(false);
  const [scores, setScores] = useState<TScore[]>([]);

  const WebSocket = useContext(WebSocketContext);
  const auth = useAuth();

  useEffect(() => {
    WebSocket.addMessageHandler<MinesweeperSquareValueOptions[][]>(
      "spr:gridChange",
      (payload: MinesweeperSquareValueOptions[][]) => {
        setGridVisibleValues(payload);
      }
    );

    WebSocket.addMessageHandler<TMinesweeperVersusSinglePlayerRankedGameStatus>(
      "spr:gameStatusChange",
      (payload: TMinesweeperVersusSinglePlayerRankedGameStatus) => {
        setIsGameDone(payload.isGameDone);
        setDidPlayerWin(payload.didPlayerWin);
        setTimerState("stop");
      }
    );

    WebSocket.addMessageHandler<TScore[]>("spr:scoresRetrieve", (payload: TScore[]) => {
      setScores(payload);
    });

    WebSocket.addMessageHandler<boolean>("spr:topTenScoreChange", (payload: boolean) => {
      setTopTenScoreChange(payload);
    });

    WebSocket.sendMessage<any>("spr:initialize", {
      userName: auth.user?.profile.name,
      gameDifficulty
    });


    return () => {
      WebSocket.removeMessageHandler("spr:gridChange");
      WebSocket.removeMessageHandler("spr:gameStatusChange");
    };
  }, [WebSocket, gameDifficulty, auth]);

  const squareClick = (y: number, x: number) => (): void => {
    if (timerState !== "start" && !isGameDone) {
      setTimerState("start");
    }

    if (
      gridVisibleValues[y][x] !== "u" ||
      gridVisibleValues[y][x] === "f" ||
      isGameDone
    )
      return;
    WebSocket.sendMessage<any>("spr:gridClick", {
      y,
      x
    });
  };
  const squareAlternateClick = (y: number, x: number) => (): void => {
    if (gridVisibleValues[y][x] === "f") {
      WebSocket.sendMessage<any>("spr:gridAlternateClick", {
        y,
        x
      });

      return;
    }

    if (gridVisibleValues[y][x] !== "u") {
      return;
    }

    WebSocket.sendMessage<any>("spr:gridAlternateClick", {
      y,
      x
    });
  };

  const onResetClick = () => {
    WebSocket.sendMessage<any>("spr:gameReset", {
      gameDifficulty
    });

    setTimerState("reset");
  };

  return (
    <SingleplayerRankedPageLayout>
      <GameGridHeader
        left={
          <Dropdown label="Menu">
            <DropdownItem
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setGameDifficulty("EASY");
              }}
            >
              Easy
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setGameDifficulty("NORMAL");
              }}
            >
              Normal
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setGameDifficulty("HARD");
              }}
            >
              Hard
            </DropdownItem>
          </Dropdown>
        }
        center={<Button onClick={onResetClick}>Reset</Button>}
        right={<TimerContainer timerState={timerState} />}
      />
      <GameGrid
        onSquareClick={squareClick}
        onSquareAlternateClick={squareAlternateClick}
        gridValues={gridVisibleValues}
        gameSize={gameDifficulty}
      />
      {isGameDone ? (
        <SingleplayerRankedPageBannerBox>
          <GameGridBanner>
            <>
              <p>{didPlayerWin ? "You Win" : "You Lose"}</p>
              {didTopTenScoreChange && <p>New top ten score!</p>}
              {didPlayerWin && <ScoreTable scores={scores}/>}
            </>
          </GameGridBanner>
        </SingleplayerRankedPageBannerBox>
      ) : null}
    </SingleplayerRankedPageLayout>
  );
};
