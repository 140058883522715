import styled from "styled-components";

interface ButtonInterface {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  width?: number;
}

const StyledButton = styled.button<ButtonInterface>`
  background: white;
  font-family: "Press Start 2P", cursive;
  display: block;
  font-size: 10px;
  height: 25px;
  width: ${(props) => props.width ?? ""}px;

  &:hover {
    color: white;
    background: red;
    cursor: pointer;
  }

  &:active {
    color: darkred;
  }

  &:disabled {
    cursor: default;
    background: rgb(146, 146, 146);
  }

  & > a {
    text-decoration: none;
  }
`;

export const Button = ({ children, onClick, width, disabled }: ButtonInterface) => {
  return (
    <StyledButton disabled={!!disabled} width={width} onClick={onClick}>
      {children}
    </StyledButton>
  );
};
