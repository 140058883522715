"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinesweeperSquareNumberColourOptions = void 0;
exports.MinesweeperSquareNumberColourOptions = {
    0: "",
    1: "#0000ff",
    2: "#008000",
    3: "#ff0000",
    4: "#000080",
    5: "#800000",
    6: "#008080",
    7: "#000000",
    8: "#808080",
};
