"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateGridVisibleValuesAfterFloodFill = exports.generateInitialGrids = void 0;
var MinesweeperGridSettings_1 = require("../constants/MinesweeperGridSettings");
var checkSurroundings = function (y, x, gridYSize, gridXSize, fn) {
    for (var dx = -1; dx <= 1; dx++) {
        if (x + dx >= 0 && x + dx < gridXSize) {
            for (var dy = -1; dy <= 1; dy++) {
                if (y + dy >= 0 && y + dy < gridYSize) {
                    fn(y + dy, x + dx);
                }
            }
        }
    }
};
var floodFill = function (y, x, gameDifficulty, tempArr, valueArr, tempSquareCounts) {
    tempArr[y][x] = valueArr[y][x];
    tempSquareCounts.count++;
    checkSurroundings(y, x, MinesweeperGridSettings_1.MinesweeperGridSettings[gameDifficulty].gridY, MinesweeperGridSettings_1.MinesweeperGridSettings[gameDifficulty].gridX, function (y2, x2) {
        if (tempArr[y2][x2] === "u" && valueArr[y2][x2] !== "m") {
            if (valueArr[y2][x2] > 0) {
                tempArr[y2][x2] = valueArr[y2][x2];
                tempSquareCounts.count++;
            }
            else {
                floodFill(y2, x2, gameDifficulty, tempArr, valueArr, tempSquareCounts);
            }
        }
    });
};
var generateInitialGrids = function (gameDifficulty) {
    var gridY = MinesweeperGridSettings_1.MinesweeperGridSettings[gameDifficulty].gridY;
    var gridX = MinesweeperGridSettings_1.MinesweeperGridSettings[gameDifficulty].gridX;
    var numOfMines = MinesweeperGridSettings_1.MinesweeperGridSettings[gameDifficulty].numOfMines;
    var tempGridValues = [];
    var tempGridVisibleValues = [];
    for (var y = 0; y < gridY; y++) {
        var valueRow = new Array(gridX).fill(0);
        var stateRow = new Array(gridX).fill("u");
        tempGridValues.push(valueRow);
        tempGridVisibleValues.push(stateRow);
    }
    for (var minesAdded = 0; minesAdded < numOfMines; minesAdded++) {
        var randX = Math.floor(Math.random() * gridX);
        var randY = Math.floor(Math.random() * gridY);
        while (tempGridValues[randY][randX] === "m") {
            randX = Math.floor(Math.random() * gridX);
            randY = Math.floor(Math.random() * gridY);
        }
        tempGridValues[randY][randX] = "m";
        checkSurroundings(randY, randX, gridY, gridX, function (y, x) {
            if (tempGridValues[y][x] !== "m") {
                //@ts-ignore
                //Ignored here as tempGridValues will be a number here
                tempGridValues[y][x] = tempGridValues[y][x] + 1;
            }
        });
    }
    return {
        gridVisibleValues: tempGridVisibleValues,
        gridValues: tempGridValues,
    };
};
exports.generateInitialGrids = generateInitialGrids;
var generateGridVisibleValuesAfterFloodFill = function (y, x, gameDifficulty, orgGridVisibleValues, gridValues) {
    var tempArr = __spreadArray([], orgGridVisibleValues, true);
    var tempSquareCounts = { count: 0 };
    floodFill(y, x, gameDifficulty, tempArr, gridValues, tempSquareCounts);
    return { tempArr: tempArr, tempSquareCounts: tempSquareCounts.count };
};
exports.generateGridVisibleValuesAfterFloodFill = generateGridVisibleValuesAfterFloodFill;
